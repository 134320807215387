import { AudienceType, Icon } from '@tytapp/common';

export interface NavItemsV1 {
    mobile: (NavItem & { icon: Icon })[];
    desktop: {
        primary: NavItem[];
        secondary: NavItem[];
    };
}

export interface NavItemBase {
    id: string;
    label: string;
    audience?: AudienceType[];
    requiresCapabilities?: string[];
    external?: boolean;
    visible?: boolean;
    icon?: Icon;
    class?: string;

    url?: string;
    action?: string;
}

export interface NavItemWithUrl extends NavItemBase {
    url: string;
}

export interface NavItemWithAction extends NavItemBase {
    action: string;
}

export interface NavItemWithSubitems extends NavItemBase {
    url: never;
    action: never;
    subitems: SubNavItem[];
}

export type NavItem = NavItemWithUrl | NavItemWithAction | NavItemWithSubitems;
export type SubNavItem = NavItem & { subitems: never };
export interface VersionedNavItems {
    [1]?: NavItemsV1;
}

/**
 * The hardcoded nav items are used if we are unable to find the nav items within the app settings, either because
 * they are not set in this environment, or because the version of the nav items data structure that we support is
 * not persisted. This means that by design, if support for older navitems versions is dropped, clients that use it
 * will fall back to the default hardcoded navigation structure. This structure should be maintained in the future.
 *
 * This structure only contains the navitems in the *currently supported version*. If the user attempts to edit navitems
 * when there is no suitable persisted dynamic navitems, we will start from this data. If other versions are supported,
 * the NavService is responsible for synthesizing them from the newest version.
 */
export const HARDCODED_NAV_ITEMS: NavItemsV1 = {
    desktop: {
        primary: [
            { id: 'home', label: 'Home', url: '/' },
            { id: 'live', label: 'Live', url: '/live' },
            { id: 'shows', label: 'Shows', url: '/shows' },
            { id: 'podcasts', label: 'Podcasts', url: '/podcasts' },
            { id: 'reports', label: 'Reports', url: '/reports' },
            { id: 'nation', label: 'TYT Nation', url: '/nation/home', visible: false },
            { id: 'resources', label: 'Resources', url: '/resources' },
        ],
        secondary: [
            {
                id: 'donate',
                label: 'Donate',
                url: 'https://secure.actblue.com/donate/tytnetwork',
                audience: ['member', 'staff'],
                requiresCapabilities: ['web_billing:contribution'],
                external: true
            },
            {
                id: 'shop',
                label: 'TYT Store',
                url: 'https://shoptyt.com/',
                external: true
            },
            {
                id: 'join',
                label: 'Join',
                url: `/join/membership`,
                audience: ['visitor', 'registered'],
                requiresCapabilities: ['billing:membership'],
                external: false
            },
            {
                id: 'sign-in',
                label: 'Sign in',
                url: '/login',
                action: "handleSignIn",
                audience: ['visitor'],
            }
        ]
    },

    mobile: [
        {
            id: 'home',
            label: 'Home',
            icon: { type: 'material', text: 'home' },
            url: '/'
        },
        {
            id: 'live',
            label: 'Watch Live',
            icon: { type: 'material', text: 'live_tv' },
            url: '/live'
        },
        {
            id: 'watch-on-demand',
            label: 'Watch On Demand',
            icon: { type: 'material', text: 'skip_next' },
            url: '/shows/all'
        },
        {
            id: 'resources',
            label: 'Resources',
            icon: { type: 'material', text: 'info' },
            url: '/resources'
        },
        {
            id: 'downloads',
            label: 'Downloads',
            icon: { type: 'material', text: 'download' },
            url: '/downloads'
        },
        {
            id: 'shows',
            label: 'Shows',
            icon: { type: 'material', text: 'tv' },
            url: '/shows',
        },
        {
            id: 'podcasts',
            label: 'Podcasts',
            icon: { type: 'material', text: 'podcasts' },
            url: '/podcasts',
        },
        {
            id: 'reports',
            label: 'Reports',
            icon: { type: 'material', text: 'article' },
            url: '/reports',
        },
        {
            id: 'topics',
            label: 'Topics',
            icon: { type: 'material', text: 'topic' },
            url: '/topics'
        },
        {
            id: 'impacts',
            label: 'Impacts',
            icon: { type: 'material', text: 'campaign' },
            url: '/impacts',
        },
        {
            id: 'nation',
            label: 'TYT Nation',
            icon: { type: 'material', text: 'groups' },
            url: '/nation/home',
            visible: false
        },
        {
            id: 'settings',
            label: 'Settings',
            icon: { type: 'material', text: 'settings' },
            url: '/settings',
            audience: ['registered', 'staff', 'member']
        },
        {
            id: 'become-member',
            label: 'Become a Member',
            icon: { type: 'material', text: 'card_membership' },
            url: '/join/membership',
            requiresCapabilities: ['billing:membership'],
            visible: false
        },
        {
            id: 'restore-purchases',
            label: 'Restore Purchases',
            icon: { type: 'material', text: 'restore' },
            requiresCapabilities: ['platform_billing:restore_purchases'],
            action: 'restorePurchases',
            visible: true
        },
        {
            id: 'shop',
            label: 'TYT Store',
            icon: { type: 'material', text: 'shopping_cart' },
            url: 'https://shoptyt.com',
            external: true,
            visible: true
        },
        {
            id: 'about',
            label: 'About',
            icon: { type: 'material', text: 'info' },
            url: '/about',
            visible: true
        }
    ]
};
