import { inject, Injectable } from '@angular/core';
import { NavItemsApi } from '@tytapp/api';
import { AppConfig, LoggerService } from '@tytapp/common';
import { HARDCODED_NAV_ITEMS, NavItem, NavItemsV1, NavItemWithSubitems, VersionedNavItems } from './nav-items';
import { firstValueFrom } from 'rxjs';
import { v4 as uuid } from 'uuid';

/**
 * The current version of the nav items structure. This should be incremented when incompatible changes are introduced
 * to the data structure that older versions of TYT.com and TYT Native will not be able to understand. It is much bett er
 * to design changes to the nav structure to be backwards compatible to avoid having to change this number.
 *
 * IF YOU CHANGE this number, you must also implement support for generating the previous versions to the setNavItems()
 * function, and also change the getNavItems() to handle upgrading old versions into the new version. The rest of TYT.com
 * handles ONLY the latest version of navitems-- the complexity of backwards compatibility must remain encapsulated here.
 */
export const NAV_ITEMS_VERSION = 1 as const;

/**
 * Provides a backwards-compatible method for dynamically overriding TYT.com's navigation menu, as well as provides a
 * default set of navigation menu items in case the backend does not have any navigation menu defined.
 *
 * Encapsulates upgrading/downgrading the nav items structure so that older versions of TYT.com and TYT Native can
 * continue to operate if/when the nav items structure is changed.
 */
@Injectable()
export class NavService {
    private navItemsApi = inject(NavItemsApi);
    public appConfig = inject(AppConfig);
    private logger = inject(LoggerService);

    /**
     * Retrieve the navigation menu items structure, which may have been conveyed to the app from the server side,
     * or may be the hardcoded built-in navigation menu.
     * @returns
     */
    async getNavItems(): Promise<NavItemsV1> {

        await this.appConfig.appStatusReady;
        if (!this.appConfig.appStatus?.settings)
            return HARDCODED_NAV_ITEMS;

        try {
            // Fetch the *current version* of the navitems structure. Older versions are retained
            // for support with older versions of TYT.com / Native still in the field.
            const versionedNavItems = <VersionedNavItems>JSON.parse(this.appConfig.appStatus.settings['nav_items']);
            const navItems = versionedNavItems[NAV_ITEMS_VERSION];

            this.logger.info(`Navigation menu from server`, versionedNavItems);
            let finalNavItems = {
                desktop: {
                    primary: navItems?.desktop?.primary ?? HARDCODED_NAV_ITEMS.desktop.primary,
                    secondary: navItems?.desktop?.secondary ?? HARDCODED_NAV_ITEMS.desktop.secondary
                },
                mobile: navItems?.mobile ?? HARDCODED_NAV_ITEMS.mobile
            };

            this.ensureIDs(finalNavItems.desktop.primary);
            this.ensureIDs(finalNavItems.desktop.secondary);
            this.ensureIDs(finalNavItems.mobile);

            return finalNavItems;
        } catch (e) {
            this.logger.error('Failed to parse navitems', e);
            return HARDCODED_NAV_ITEMS;
        }
    }

    /**
     * Retrieve the nav items directly from the API instead of using the ones that arrived along with the application
     * initialization call. This ensures that the editor always shows the latest navigation items, even if they have
     * not taken effect for this application yet.
     * @returns
     */
    async getNavItemsForEditing(): Promise<NavItemsV1> {
        let versionedNavItems = <VersionedNavItems> await firstValueFrom(this.navItemsApi.getNavItems());
        let navItems = versionedNavItems[1];
        let finalNavItems = {
            desktop: {
                primary: navItems?.desktop?.primary ?? HARDCODED_NAV_ITEMS.desktop.primary,
                secondary: navItems?.desktop?.secondary ?? HARDCODED_NAV_ITEMS.desktop.secondary,
            },
            mobile: navItems?.mobile ?? HARDCODED_NAV_ITEMS.mobile
        }

        this.ensureIDs(finalNavItems.desktop.primary);
        this.ensureIDs(finalNavItems.desktop.secondary);
        this.ensureIDs(finalNavItems.mobile);

        return finalNavItems;
    }

    async setNavItems(navItems: NavItemsV1) {
        // When changing the format of the nav items, this function will be responsible for filling out
        // old versions of the navitems structure, for backwards compatibility
        await firstValueFrom(this.navItemsApi.setNavItems({
            nav_items: <VersionedNavItems>{ [NAV_ITEMS_VERSION]: navItems }
        }));
    }

    hasSubItems(item: NavItem): item is NavItemWithSubitems {
        return 'subitems' in item;
    }

    visitItems(navItems: NavItem[], visitor: (item: NavItem) => boolean): boolean {
        for (let navItem of navItems) {
            if (!visitor(navItem))
                break;
            if (this.hasSubItems(navItem)) {
                if (!this.visitItems(navItem.subitems, visitor))
                    break;
            }
        }

        return true;
    }

    ensureIDs(navItems: NavItem[]) {
        this.visitItems(navItems, item => (item.id ??= uuid(), true));
    }

}